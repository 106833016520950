import React, { Component } from 'react';
import styled from 'styled-components'
import Layout from 'components/layout'
import Helmet from 'react-helmet'
import { Badge, Row, Col } from 'react-bootstrap'
import { graphql, Link } from 'gatsby'
import AnimationContainer from 'components/animation-container'
import { InnerContent, MainContainer, ImageWrapper } from 'components/container'
import ColumnContainer from 'components/column'
import { Separator } from 'components/separator'
import Metadata from '../../constants/metadata'
import Section from 'components/section'
import { GrowButtonLarge } from 'components/button'
import { Theme } from '../../constants/variables'
import { ButtonStyle } from '../../constants/design'
import Loading from '../../pages/loading'
import { Heading1, Heading2, Heading4, ParagraphText } from 'components/text'
import 'animate.css/animate.compat.css'
import imageUrlBuilder from '@sanity/image-url'

const BlockContent = require('@sanity/block-content-to-react')
class SanityAPI {
  static client = require('@sanity/client')({
    projectId: 'vvdn855b',
    dataset: 'production',
    useCdn: true
  });

  static getProjects = async (slug) => {
    const query = '*[slug.current == $slug] { id, title, headline, subheadline, metaDescription, headlinePhoto, contents, tags }'
    const projects = await SanityAPI.client.fetch(query, { slug: slug })
    return projects
  }

  static urlFor = (source) => {
    const builder = imageUrlBuilder(SanityAPI.client)
    return builder.image(source)
  }
}

const LOADING_TIME_WAIT_MS = 1500

class PortfolioDetail extends Component {
  state = {
    project: null
  }

  componentDidMount() {
    this.renderProject();
  }

  renderTags(tags) {
    // Container for tags included in featured project.
    const TagContainer = styled.div`
      display: inline-block;
      width: 100%;
      margin-top: ${Theme.spacing.one};
      margin-bottom: ${Theme.spacing.two};
      span:not(:first-child) {
        margin-left: ${Theme.spacing.one};
      }
      span:not(:last-child) {
        margin-right: ${Theme.spacing.one};
      }
    `
    // Generate JSX for all tags
    const tagsJSX = tags.map((item, index) => {
      const tagKey = index + "_" + item + "_Tag"
      return (
        <Badge key={tagKey} variant="primary">{item}</Badge>
      )
    })
    // Render tag container + tags
    return (<TagContainer>{tagsJSX}</TagContainer>)
  }

  renderImage(sourceURL) {
    // TODO: Theming, pixel-pushing on mobile vs. desktop margins.
    const ImageContainer = styled.div`
      width: auto;
      margin-top: 80px;
      margin-bottom: ${Theme.spacing.two};
      @media (max-width: 767px) {
        margin-top: 0px;
      }
    `
    const Image = styled.img`
      width: 100%;
      height: auto;
      margin: auto;
      object-fit: cover;
      transition: .5s;
      display: inline-block;
    `
    return sourceURL ? (<ImageContainer><Image src={sourceURL} /></ImageContainer>) : ('')
  }

  renderProject = async () => {
    // GUARD: Already rendered project.
    if (this.state.project) { return }

    // GUARD: Slug must exist to fetch
    const slug = this.props.pageContext.slug
    if (!slug) { return }

    // Fetch latest project from Sanity API
    try {
      const projects = await SanityAPI.getProjects(slug)
      // Update UI if project found
      if (projects.length) {
        this.setState({ project: projects[0] })
      }
    }
    catch (err) {
      console.error('Error: ', err);
    }
  }

  sleep = milliseconds => {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  };

  render() {
    const isLoading = this.state.project == null

    //
    // GUARD: Loading State
    //
    if (isLoading) { return (<Loading isLoading={true} />) }

    //
    // Project Details Loaded
    // 
    const project = this.state.project
    return (
      <div>
        <Helmet>
          <title>{Metadata.Portfolio.metaTitle}</title>
          <meta name="title" content={Metadata.Portfolio.metaTitle} />
          <meta name="description" content={Metadata.Portfolio.metaDescription} />
        </Helmet>
        <Layout
          isHome={false}
          useReveal={false}
          sections={Metadata.Navigation.Links}
        >
          <Section id="about" style={{ backgroundColor: Theme.color.dark }}>
            <MainContainer>
              <Row>
                <Col md={1} className="d-md-block d-sm-none"></Col>
                <Col md={4} xs={12} className="animate__animated animate__fadeIn">
                  {this.renderImage(SanityAPI.urlFor(project.headlinePhoto).width(1200).url())}
                </Col>
                <ColumnContainer md={7} xs={12}>
                  <AnimationContainer animation="fadeIn">
                    <InnerContent>
                      <Heading1>{project.headline}</Heading1>
                      <Separator marginBottom={4} />
                      <Heading4>
                        {project.subheadline}
                      </Heading4>
                      <BlockContent
                        blocks={project.contents}
                        renderContainerOnSingleChild={true} />
                      {this.renderTags(project.tags)}
                      <ParagraphText style={{ marginTop: Theme.spacing.four }}>
                        Ready to get your project off the ground?
                      </ParagraphText>
                      <GrowButtonLarge>
                        <Link to={Metadata.About.callToActionLink}>
                          <span>{Metadata.Actions.GiveUsAShout}</span>
                          <span style={ButtonStyle.growButtonLargeArrow}>→</span>
                        </Link>
                      </GrowButtonLarge>
                    </InnerContent>
                  </AnimationContainer>
                </ColumnContainer>
              </Row>
            </MainContainer>
          </Section>
        </Layout>
      </div>)
  }
}

export default PortfolioDetail;
